<omg-card class="workspace-card"
          [loading]="!(summary | ngrxPush)"
          omgTheme>
  <ng-template #loadedRef>

    <div data-cy="note-content"
         *ngIf="summary | ngrxPush as summary">
      <omg-card-title>
        <div class="om-tab-subheading"
             omgCollapse
             [expanded]="summary.note.totalComments > 0"
             #commentsRef="omgCollapse"
             om-layout
             om-layout-fill
             om-layout-gutter
             om-layout-align="space-between center">
          <omg-note-icon [tags]="summary?.noteType?.tags"
                         [noteType]="summary?.noteType?.name"></omg-note-icon>

          <span om-flex
                *ngIf="!summary.signed">
            <span om-flex
                  *ngIf="isAppointmentSummary | ngrxPush">
              <strong>{{ summary.appointment?.type }}</strong>
              ({{ summary.appointment?.startAt | omgDate: '12Time' }}—{{
              summary.appointment?.endAt | omgDate: '12Time'
              }})
              {{ summary.appointment?.startAt | omgDate: '2y' }}
            </span>

            <span om-flex
                  *ngIf="isNoteOrWalkinVisit | ngrxPush"
                  data-cy="summary-note-title"
                  class="summary-note-title">
              {{ summary.noteType.name }} Note
            </span>
          </span>
          <span om-flex
                *ngIf="summary.signed"
                om-layout="vertical">
            <span class="summary-note-title">{{ summary.subject | slice: 0:80 }}</span>
            <omg-note-history *ngIf="summary.signed"
                              [note]="summary"></omg-note-history>
          </span>

          <!-- Comment icon / toggle -->
          <omg-collapse-toggle om-layout
                               om-layout-align="space-between center"
                               om-layout-gutter-mini
                               class="collapse-with-flex"
                               (toggle)="trackCommentToggle($event)">
            <omg-collapsed disableAnimation="true">
              <span class="om-icon icon-comment clickable"></span>
            </omg-collapsed>

            <omg-expanded disableAnimation="true">
              <span class="om-icon icon-comment-filled clickable"></span>
            </omg-expanded>
            <span>{{ summary?.note?.totalComments }}</span>
          </omg-collapse-toggle>

          <!-- Close summary button -->
          <span class="om-icon icon-close clickable"
                omStopEvent="click"
                data-cy="close-note-btn"
                *ngIf="!(workspaceExpanded$ | ngrxPush)"
                (click)="onCloseSummary(summary)"></span>

          <!-- Collapse service ticket button -->
          <span class="fa fa-expand clickable"
                omStopEvent="click"
                data-cy="collapse-note-btn"
                *ngIf="workspaceExpanded$ | ngrxPush"
                (click)="closeServiceTicket()"></span>
        </div>
      </omg-card-title>
      <omg-healthscribe-banner *ngIf="showHealthscribeBanner(summary) && summary.appointment?.id"
                               [appointmentId]="summary.appointment!.id" />

      <omg-unsigned-summary *ngIf="!summary.signed"
                            [summary]="summary"
                            [todo$]="todo"
                            [commentsCollapseRef]="commentsRef"></omg-unsigned-summary>
      <omg-signed-summary *ngIf="summary.signed"
                          [summary]="summary"
                          [todo]="todo | ngrxPush"
                          [commentsCollapseRef]="commentsRef"
                          [patient]="patient | ngrxPush"
                          [profilePrimaryName]="profilePrimaryName | ngrxPush"
                          [hasIncompleteCosignTodo]="hasIncompleteCosignTask | ngrxPush"
                          [note]="note | ngrxPush"></omg-signed-summary>
    </div>
  </ng-template>
  <ng-template #loadingRef>
    <omg-card-title heading="Summary">
    </omg-card-title>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
</omg-card>
