import { DOCUMENT } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { ConfigService, ProfileSelectors } from '@app/core';
import { Profile } from '@app/core/profile/shared/profile.type';
import { DatePipe } from '@app/shared/pipes';
import { windowToken as WINDOW } from '@app/shared/window/window.service';
import { ZendeskService } from '../zendesk/zendesk.service';
import * as Sentry from '@sentry/angular-ivy';

/**
 * Initializes Pendo product adoption library.
 * https://support.pendo.io/hc/en-us/articles/360046272771
 * The account id sent with the initialization block is simply a
 * value that we assigned to represent our "group" of internal users.
 */
@NgModule()
export class PendoModule {
  zendeskToken: any;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private profileSelectors: ProfileSelectors,
    private zendesk: ZendeskService,
  ) {
    const pendo = configService.environment.pendo;
    if (pendo && pendo.apiKey) {
      this.init();
    }
  }

  private init(): void {
    // do not load pendo if the authenticated user is an automated staging account.
    // we don't want to break automated tests with pendo content.

    this.profileSelectors.profile
      .pipe(
        first(profile => (profile ? true : false)),
        filter(profile => !this.isAutomatedTestingAccount(profile)),
      )
      .subscribe(profile => {
        // pendo script must be injected before zendesk script
        this.injectScript();
        this.zendesk.injectScript();
        this.zendesk.authenticate();
        this.initPendo(profile);
      });
  }

  private injectScript(): void {
    const script = `
      (function(apiKey) {
        (function(p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
          for (w = 0, x = v.length; w < x; ++w)(function(m) {
            o[m] = o[m] || function() {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
          })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = 'https://content.pendo.ui.1life.com/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
      })('${this.configService.environment.pendo.apiKey}');
    `;
    const el = this.document.createElement('script');
    el.type = 'text/javascript';
    el.innerHTML = script;
    this.document.head.appendChild(el);
  }

  private initPendo(profile: Profile): void {
    const email = this.getPrimaryEmail(profile);
    const tags = this.getTags(profile);
    const datepipe: DatePipe = new DatePipe('en-US');
    const hireDate = datepipe.transform(
      profile.roleDetails[0].createdAt,
      'MM-dd-yyyy',
    );
    this.window.pendo.initialize({
      visitor: {
        id: profile.id,
        email,
        full_name: profile.displayName,
        role: profile.roles,
        tags,
        office: profile.office.name,
        hire_date: hireDate,
      },
      account: {
        id: 'Internal Users',
      },
      // ensure pendo doesn't attempt to send any PII text from the DOM.
      // we have this enabled at the subscription level but this makes
      // it explicitly clear for developers.
      excludeAllText: true,
    });
  }

  private isAutomatedTestingAccount(profile: Profile): boolean {
    const lastNameStaging = profile.lastName.includes('staging');
    const hasOneMedicalEmail = profile.identities.some(identity =>
      identity.email.includes('onemedical'),
    );
    return lastNameStaging && hasOneMedicalEmail;
  }

  private getPrimaryEmail(profile: Profile): string | undefined {
    const loginIdentity = profile.identities.find(identity => identity.login);
    if (!loginIdentity) {
      const error = new Error(
        `user [${profile.id}]' lacks a login identity; pendo user will be initialized without an email address`,
      );
      Sentry.captureException(error);
    }
    return loginIdentity?.email;
  }

  private getTags(profile: Profile): string[] {
    // when a pendo user gives feedback through a feedback module, their feedback will be automatically
    // tagged with the respective role. this makes it easier for our product support team to coallesce/organize
    // the feedback
    // see https://support.pendo.io/hc/en-us/articles/360033317371-Auto-tagging-in-Feedback
    if (
      profile.roles.includes('provider') &&
      profile.roles.includes('clinical_support_team')
    ) {
      return ['vmt provider'];
    }
    if (profile.roles.includes('provider')) {
      return ['in-office provider'];
    }
    if (profile.roles.includes('phlebotomist')) {
      return ['phleb'];
    }
    if (profile.roles.includes('admin')) {
      return ['admin'];
    }
    return [];
  }
}
