// @ts-strict-ignore
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { Patient, PatientSelectors, ProfileSelectors } from '@app/core';
import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { mapNoteTypeToAnalyticsSubcomponent } from '@app/features/timeline/shared/timeline.mapper';
import { NoteApiService } from '@app/modules/note/shared/note-api.service';
import { Note } from '@app/modules/note/shared/note.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { filterTruthy } from '@app/utils';

import { PatientChartActions } from '../../../patient-chart/store/patient-chart.actions';
import { PatientChartSelectors } from '../../../patient-chart/store/patient-chart.selectors';
import { isWalkinVisit } from '../../shared/summaries-utils';
import { SummariesGuard } from '../../shared/summaries.guard';
import { Summary } from '../../shared/summaries.type';
import { SummariesActions } from '../../store/summaries.actions';
import { SummariesSelectors } from '../../store/summaries.selectors';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';

@Component({
  selector: 'omg-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.scss'],
})
export class SummariesComponent implements OnInit, OnDestroy {
  summary: Observable<Summary>;
  todo: Observable<Todo>;
  patient: Observable<Patient>;
  profilePrimaryName: Observable<string>;
  hasIncompleteCosignTask: Observable<boolean>;
  note: Observable<Note>;

  workspaceExpanded$: Observable<boolean>;

  private unsubscribe = new Subject<void>();

  constructor(
    private summarySelectors: SummariesSelectors,
    private todoSelectors: TodoSelectors,
    private summariesActions: SummariesActions,
    private patientChartSelectors: PatientChartSelectors,
    private patientChartActions: PatientChartActions,
    private patientSelectors: PatientSelectors,
    private profileSelectors: ProfileSelectors,
    private noteApiService: NoteApiService,
    private summariesGuard: SummariesGuard,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private launchDarkly: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.summariesGuard
      .canActivate(this.route.snapshot)
      .pipe(
        filterTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();
  }

  private healthscribeFlag: boolean | undefined;
  showHealthscribeBanner(summary: Summary): boolean {
    this.healthscribeFlag ??= this.launchDarkly.variation<boolean>(FeatureFlagNames.healthscribeBannerVersionOne, false)

    // Only show if the summary hasn't been signed yet.
    const shouldShow = !summary.signed

    return this.healthscribeFlag && shouldShow
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.closeServiceTicket();
  }

  onCloseSummary(summary: Summary) {
    this.trackCloseItem(summary);
    this.closeServiceTicket();
    this.summariesActions.closeWorkspaceItem();
  }

  closeServiceTicket() {
    this.patientChartActions.expandWorkspace(false);
  }

  get isNoteOrWalkinVisit(): Observable<boolean> {
    return this.summary.pipe(
      map(
        summary =>
          !summary.appointment ||
          (summary.noteType && isWalkinVisit(summary.noteType)) ||
          summary.hasProgramVisit,
      ),
    );
  }

  get isAppointmentSummary(): Observable<boolean> {
    return this.summary.pipe(
      map(
        summary =>
          !!summary.appointment &&
          !(summary.noteType && isWalkinVisit(summary.noteType)) &&
          !summary.hasProgramVisit,
      ),
    );
  }

  trackCommentToggle(expanded: boolean): void {
    this.summary.subscribe(summary => {
      this.analytics.track(AnalyticsEvent.AddCommentClicked, {
        workflow: 'Charting',
        component: summary.noteType.name,
        summaryId: summary.id,
        subcomponent: 'Add Comment Button',
        method: expanded ? 'Expand' : 'Collapse',
        activeCharting: true,
      });
    });
  }

  private setupSelectors() {
    this.patient = this.patientSelectors.patient;

    this.profilePrimaryName = this.profileSelectors.primaryIdentityName;

    this.workspaceExpanded$ = this.patientChartSelectors.workspaceExpanded;

    this.summary = this.summarySelectors.currentSummary.pipe(
      filter((summary): summary is Summary => (summary ? true : false)),
    );

    this.todo = this.summary.pipe(
      switchMap(summary => this.todoSelectors.todoBySummaryId(summary.id)),
      filter(todo => (todo ? true : false)),
    );

    this.hasIncompleteCosignTask = combineLatest([
      this.summary,
      this.todo,
    ]).pipe(
      switchMap(([summary, todo]) => {
        return this.todoSelectors
          .isIncompleteAndCosign(todo.id)
          .pipe(
            map(
              isIncompleteAndCosign =>
                isIncompleteAndCosign !== undefined &&
                isIncompleteAndCosign &&
                summary.signed,
            ),
          );
      }),
    );

    this.note = this.summary.pipe(
      switchMap(summary => this.noteApiService.get(summary.note.id)),
    );

    this.trackSummaryViewed();
  }

  private trackCloseItem(summary: Summary): void {
    this.analytics.track(AnalyticsEvent.ClinicalTimelineObjectClosed, {
      workflow: 'Charting',
      component: 'Clinical Timeline',
      activeCharting: true,
      subcomponent: mapNoteTypeToAnalyticsSubcomponent(summary.noteType.name),
      noteId: summary.note.id,
      orderId: null,
      summaryId: summary.id,
      patientTimelinePostId: null,
      method: 'Push',
    });
  }

  trackSummaryViewed(): void {
    this.summary
      .pipe(first(summary => (summary ? true : false)))
      .subscribe(summary => {
        this.analytics.track(AnalyticsEvent.SummaryViewed, {
          ...DefaultAnalyticsProps,
          component: 'Workspace',
          subcomponent: 'Start Visit Button',
          appointmentId: summary.appointment?.id,
          appointmentType: summary.appointment?.type,
          summaryId: summary.id,
        });
      });
  }
}
